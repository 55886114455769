import { initGlobalData } from "./global";
import { MapAndFiltersComponent } from "./components/MapAndFiltersComponent";
import { GenericPopupComponent } from "./components/GenericPopup";
import { SignupComponent } from "./components/Signup";
import { PleaseLoginPopupComponent } from "./components/PleaseLoginPopup";
import { attachAddNewObjectHandlers } from "./components/AddNewObject";

const onPageLoad = async () => {
  initGlobalData();

  const pleaseLoginPopup = new GenericPopupComponent("#please-login-block", {
    contentComponent: PleaseLoginPopupComponent,
    data: {},
  });
  pleaseLoginPopup.render();
  attachAddNewObjectHandlers(pleaseLoginPopup.show.bind(pleaseLoginPopup));

  const signup = new SignupComponent("#login-signup-block");
  window.global_data.signupComponent = signup;
  signup.render();

  const mapAndFilters = new MapAndFiltersComponent(".map-and-filters").render();
};

onPageLoad();
