import axios from "axios";
import { API_URL, getFieldConfig } from "./main_module";

export function fetchItemsByCategory(id) {
  return axios(API_URL + "/items/category/" + id);
}

export const unpackObject = (obj) => {
  const fields = JSON.parse(obj.custom_fields || "{}");
  if (obj.items) {
    for (let k in fields) {
      const conf = getFieldConfig(k);
      if (conf?.field_data?.category_id) {
        const that_items = obj.items.filter(
          (i) => i.category_id == conf.field_data.category_id
        );
        //if (conf?.field_data?.multiple) {
        fields[k] = that_items;
        //} else {
        //  fields[k] = that_items[0];
        //}
      }
    }
  }
  const res = { ...obj, fields };
  //console.log("Unpacked object", res);
  return res;
};

export const loadObjectData = (object_id) => {
  console.log("loading object", object_id);
  return axios({
    method: "get",
    url: API_URL + "/objects/" + object_id,
  }).then((res) => {
    return unpackObject(res.data);
  });
};

export const loadObjects = async (data) =>
  axios({
    method: "get",
    url: API_URL + "/objects?" + new URLSearchParams(data).toString(),
  })
    .then((res) => {
      return res.data.map((obj) => unpackObject(obj));
    })
    .catch((e) => {
      console.error("Error while unpacking the markers");
      return [];
    });

export const editObject = (id, vals) => {
  const fd = new FormData();
  for (let k in vals) {
    fd.append(k, vals[k]);
  }
  /*for (var i in files) {
        if (files.hasOwnProperty(i)) {
            fd.append("photo" + i, files[i]);
        }
    }*/
  console.log("FormData", fd);
  return axios({
    method: "post",
    url: API_URL + "/objects/edit/" + id,
    data: fd,
    headers: {
      Authorization: "Bearer " + window.token,
    },
  });
};

export const submitNewObject = async (vals, files) => {
  const fd = new FormData();
  for (let k in vals) {
    fd.append(k, vals[k]);
  }
  for (var i in files) {
    if (files.hasOwnProperty(i)) {
      fd.append("photo" + i, files[i]);
    }
  }
  return axios({
    method: "post",
    url: API_URL + "/objects/add",
    data: fd,
    headers: {
      Authorization: "Bearer " + window.token,
    },
  });
};

export const submitNewPhoto = async (object_id, files) => {
  const fd = new FormData();
  fd.append("object_id", object_id);
  for (var i in files) {
    if (files.hasOwnProperty(i)) {
      fd.append("photo" + i, files[i]);
    }
  }
  return axios({
    method: "post",
    url: API_URL + "/photo/add",
    data: fd,
    headers: {
      Authorization: "Bearer " + window.token,
    },
  });
};
