import L from "leaflet";
import "leaflet-providers";
import "leaflet.markercluster";
import { allFieldsConfig } from "./components/formFields";

export const qs = document.querySelector.bind(document);
export const qsa = document.querySelectorAll.bind(document);

const SITENAME = "archimapa";
export const API_URL = "https://map.transsearch.net";

(function () {
  var buildingIcon = L.icon({
    iconUrl: new URL("archimapa/building.png", import.meta.url),
    iconSize: [30, 30],
    iconAnchor: [10, 10],
    //popupAnchor: [5, 10],
    //shadowSize: [30, 95],
    //shadowAnchor: [22, 94]
  });
  var industryIcon = L.icon({
    iconUrl: new URL("archimapa/factory.png", import.meta.url),
    iconSize: [30, 30],
    iconAnchor: [10, 10],
    //popupAnchor: [5, 10],
    //shadowSize: [30, 95],
    //shadowAnchor: [22, 94]
  });

  window.site_specific_funcs = {
    getMarkerIcon: (obj_data) => {
      //console.log('.........../././', obj_data);
      return obj_data?.fields?.building_type == 1 ? buildingIcon : industryIcon;
    },
  };
})();

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie =
    name + "=" + (value || "") + expires + "; SameSite=Lax; path=/";
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

window.token = getCookie("jwt_auth");

export const setToken = (newToken) => {
  token = newToken;
  setCookie("jwt_auth", token, 7);
  global_data.signupComponent.render();
};

export const setOnClick = (root, str, handler) => {
  const node = root.querySelector(str);
  if (!node) return;
  node.onclick = handler;
};

export const objToFormData = (obj) => {
  const fd = new FormData();
  for (let i in obj) {
    fd.append(i, obj[i]);
  }
  return fd;
};

export const getFieldsListConfig = (values, type = "display") => {
  return getSiteSpecific("objectCustomFields", []).map((field) => ({
    value: values[field.key] || "",
    key: field.key,
    component: allFieldsConfig[field.type][type],
    title: field.title,
    field_data: field.field_data,
  }));
};

export const getFieldConfig = (fieldName) => {
  return getSiteSpecific("objectCustomFields", []).find(
    (f) => f.key === fieldName
  );
};

export const getSiteSpecific = (key, fallback) => {
  if (!global_data.siteSpecificConfigs) {
    debugger;
  }
  return global_data.siteSpecificConfigs[key] !== undefined
    ? global_data.siteSpecificConfigs[key]
    : fallback;
};

export const runSiteSpecific = (key, ...args) => {
  if (!window.site_specific_funcs || !window.site_specific_funcs[key]) {
    console.error(
      "Cannot run site specific: " + key,
      window.site_specific_funcs
    );
    return;
  }
  const res = window.site_specific_funcs[key](...args);
  return res;
};

export const getUrlParam = (key) => {
  var url = new URL(window.location);
  var c = url.searchParams.get(key);
  console.log(c);
  return c;
};

export const show = (node) => {
  node.style.display = "block";
};
export const hide = (node) => {
  node.style.display = "none";
};

export const getl = (key) => {
  return global_data.texts[key] || key;
};

export const initPage = async () => {
  return Promise.all([]);
};
