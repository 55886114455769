import { Component } from "./GenericComponent";
import { API_URL } from "../main_module";

export class PhotoTilesComponent extends Component {
  getHTML = () => {
    return `
        <div class="object-popup-photos">
        ${this.data?.images
          ?.map(
            (i) =>
              `<a href="${API_URL + i.full_url}"><img src="${
                API_URL + i.preview_url
              }" /></a>`
          )
          .join("")}
    </div>`;
  };
  afterRender = () => {
    lightGallery(this.qs(".object-popup-photos"));
  };
}
