import axios from "axios";
import { getl, hide, objToFormData, setToken, show } from "../main_module";
import { FieldsEditListComponent } from "./FieldsEditList";
import {
  CheckboxFieldEditComponent,
  PasswordFieldEditComponent,
  TextFieldEditComponent,
} from "./formFields";
import { Component } from "./GenericComponent";
import { API_URL } from "../main_module";

export class LoginPopupComponent extends Component {
  getHTML = () => {
    return `
          <div class="login-popup-root">
              <h3>
                  ${getl("login")}
              </h3>
              <a target="_blank" href="https://accounts.google.com/o/oauth2/auth?redirect_uri=https%3A%2F%2Fmap.transsearch.net%2Fauth%2Fgoogle&response_type=code&client_id=518985853604-85mlpc3ot7bs092ojsuuc4511ooofo58.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile">
                Google Auth
              </a>
              <div>
                  <div class="form-fields">
                  </div>
                  <div class="form-errors">
  
                  </div>
                  <div>   
                      <button class="submit-button">${getl("submit")}</button>
                  </div>
              </div>
          </div>
          `;
  };
  afterRender = () => {
    this.mountNode.querySelector(".submit-button").onclick = () => {
      hide(this.mountNode.querySelector(".form-errors"));
      const [vals] = this.renderedChildren[0].getValues();
      const { username, password, rememberMe } = vals;
      let err;
      if (!username) {
        err = getl("please_enter_login");
      }
      if (!password) {
        err = getl("please_enter_password");
      }
      if (err) {
        this.mountNode.querySelector(".form-errors").innerHTML = err;
        show(this.mountNode.querySelector(".form-errors"));
      } else {
        axios
          .post(
            API_URL + "/auth/login",
            objToFormData({
              username,
              password,
              rememberMe: Number(rememberMe),
            })
          )
          .then((res) => {
            setToken(res.data.token);
            this.data.close();
            showNotification(get("you_successfully_logged_in"), "success");
          })
          .catch((e) => {
            show(this.mountNode.querySelector(".form-errors"));
            this.mountNode.querySelector(".form-errors").innerHTML =
              e.text || getl("could_not_log_in");
          });
      }
    };
  };
  getChildren = () => {
    return [
      {
        selector: ".form-fields",
        component: FieldsEditListComponent,
        getData: () => {
          return {
            fieldsConfig: [
              {
                value: "",
                title: getl("username"),
                key: "username",
                component: TextFieldEditComponent,
              },
              {
                value: "",
                title: getl("password"),
                key: "password",
                component: PasswordFieldEditComponent,
              },
              {
                value: false,
                title: getl("rememberMe"),
                key: "rememberMe",
                component: CheckboxFieldEditComponent,
              },
            ],
          };
        },
      },
    ];
  };
}
