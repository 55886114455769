const qs = document.querySelector.bind(document);
const qsa = document.querySelectorAll.bind(document);
import { setOnClick } from "../main_module";

export class Component {
  constructor(mountNode, data) {
    if (!mountNode) {
      throw new Exception();
    }
    if (typeof mountNode === "string") {
      mountNode = qs(mountNode);
    }
    this.mountNode = mountNode;
    this.data = data;
    this.renderedChildren = [];
    this.onCreate();
  }
  name = "Component";
  afterRender() {}
  getChildren = () => {
    //debugger;
    return [];
  };
  onCreate() {}
  async render() {
    this.mountNode.innerHTML = this.getHTML();
    await Promise.all(
      this.getChildren().map(async (child, i) => {
        let data = {};
        if (child.getData) {
          data = await child.getData();
        }
        if(!child.component) debugger;
        const Component = new child.component(
          this.mountNode.querySelector(child.selector),
          data
        );
        await Component.render();
        this.renderedChildren[i] = Component;
      })
    );
    this.afterRender();
  }
  getHTML() {
    return "<div>I'm component</div>";
  }

  qs(str) {
    return this.mountNode.querySelector(str);
  }
  qsa(str) {
    return this.mountNode.querySelectorAll(str);
  }

  click(str, handler) {
    setOnClick(this.mountNode, str, handler);
  }
}
