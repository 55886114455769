import { Component } from "./GenericComponent";
import { GenericPopupComponent } from "./GenericPopup";
import { getl, setOnClick, setToken } from "../main_module";
import { LoginPopupComponent } from "./LoginPopup";
import { SignupPopupComponent } from "./SignupPopup";

export class SignupComponent extends Component {
  getHTML = () => {
    return (
      `
          <div class="login-signup-root">
              ` +
      (!token
        ? `<button class="login-button">
                      ${getl("login")}
                  </button>
                  <button class="signup-button">
                      ${getl("signup")}
                  </button>`
        : `
                  <button class="logout-button">
                      ${getl("logout")}
                  </button>`) +
      `
              <div class="login-popup">
              </div>
              <div class="signup-popup">
              </div>
          </div>
          `
    );
  };
  afterRender = () => {
    //console.log("----------------------> AFTER RENDER");
    global_data.loginPopup = this.renderedChildren[0];
    global_data.signupPopup = this.renderedChildren[1];
    setOnClick(this.mountNode, ".login-button", () => {
      this.renderedChildren[0].show();
    });
    setOnClick(this.mountNode, ".signup-button", () => {
      this.renderedChildren[1].show();
    });
    setOnClick(this.mountNode, ".logout-button", () => {
      setToken(false);
    });
    //console.log("---------------------->", this.renderedChildren);
  };
  getChildren = () => {
    return [
      {
        component: GenericPopupComponent,
        selector: ".login-popup",
        getData: () => {
          return {
            contentComponent: LoginPopupComponent,
            data: {
              close: () => {
                this.renderedChildren[0].hide();
              },
            },
          };
        },
      },
      {
        component: GenericPopupComponent,
        selector: ".signup-popup",
        getData: () => {
          return {
            contentComponent: SignupPopupComponent,
            data: {
              foo: "bar",
            },
          };
        },
      },
    ];
  };
}
