import { Component } from "./GenericComponent";
import { getSiteSpecific, getl } from "../main_module";
import { fetchItemsByCategory } from "../api";

class CheckboxFilterComponent extends Component {
  constructor(...args) {
    super(...args);
    this.vals = new Set();
  }
  onCreate() {
    fetchItemsByCategory(this.data.category_id).then((res) => {
      this.items = res.data;
      this.render();
    });
  }
  afterRender() {
    this.mountNode.onclick = (e) => {
      if (e.target.checked) {
        this.vals.add(e.target.value);
      } else {
        this.vals.delete(e.target.value);
      }
      this.data.onChange(this.data.category_id, [...this.vals]);
    };
  }
  getHTML = () => {
    return `<h3 class="filter-title">${getl(this.data.title)}</h3><ul>
          ${
            this?.items
              ? this.items
                  .map(
                    (item) =>
                      `<li><input type="checkbox" value="${item.id}" data-id="${item.id}">${item.title}</li>`
                  )
                  .join("")
              : "Loading..."
          }
      </ul>`;
  };
}
class SelectFilterComponent extends Component {
  onCreate() {
    fetchItemsByCategory(this.data.category_id).then((res) => {
      this.items = res.data;
      this.render();
    });
  }
  getHTML = () => {
    return `<div><h3 class="filter-title">${getl(this.data.title)}</h3><ul>
          ${
            this?.items
              ? `<select><option>${getl("please_select")}</option>${this.items
                  .map(
                    (item) =>
                      `<option value="${item.id}">${item.title}</option>`
                  )
                  .join("")}</select>`
              : "Loading..."
          }
      </ul></div>`;
  };
  afterRender() {
    if (this.mountNode.querySelector("select")) {
      this.mountNode.querySelector("select").onchange = (e) => {
        this.data.onChange(this.data.category_id, e.target.value);
      };
    }
  }
}

const filterComponentMapping = {
  checkboxList: CheckboxFilterComponent,
  select: SelectFilterComponent,
};

export class FiltersWidgetComponent extends Component {
  constructor(...args) {
    super(...args);
    this.vals = {};
  }
  getHTML = () => {
    const filtersConfig = getSiteSpecific("filtersConfig");
    return `
          <ul class="filters-list">
              ${filtersConfig
                ?.map((f, i) => '<li class="item-' + i + '"></li>')
                .join("")}
          </ul>
      `;
  };
  afterRender = () => {};

  getChildren = () => {
    const filtersConfig = getSiteSpecific("filtersConfig");
    return filtersConfig?.map((f, i) => {
      return {
        selector: ".item-" + i,
        component: filterComponentMapping[f.type],
        getData: () => {
          return {
            ...f,
            onChange: (key, val) => {
              this.vals[key] = val;
              window.global_data.setFilters(this.vals);
            },
          };
        },
      };
    });
  };
}
