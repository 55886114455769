import { Component } from "./GenericComponent";

export class ImageUploadComponent extends Component {
  getHTML = () => {
    return `
              <div>
                  <div class="form-caption">
                      Завантажити фото
                  </div>
                  <div class="form-value">
                      <input class="image-select" type="file" multiple />
                  </div>
              </div>
          `;
  };
  getFiles() {
    return this.mountNode.querySelector(".image-select").files;
  }
}
