
import { FieldsListComponent } from "./FieldsList";

export class FieldsEditListComponent extends FieldsListComponent {
    getValues = () => {
      const virtualFieldsValues = {};
      const items = [];
      this.renderedChildren.forEach((child) => {
        if (!child) return;
        const val = child.getValue();
        virtualFieldsValues[child.data.key] = val;
        if (child.data.category_id) {
          items.push(val);
        }
      });
      console.log("=== FORM_VALUES_GET: ", virtualFieldsValues);
      return [virtualFieldsValues, items];
    };
  }