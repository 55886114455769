import { Component } from "./GenericComponent";
import { getl } from "../main_module";

export class PleaseLoginPopupComponent extends Component {
  getHTML = () => {
    return `
          <div class="please-login-popup-root">
              ${getl("please_login_or_signup_to_add_objects")}
             <div>
              <button class="login-button">
                  ${getl("login")}
              </button>
              <button class="signup-button">
                  ${getl("signup")}
              </button>
             </div>
          </div>
          `;
  };
  afterRender = () => {
    let l, s;
    if ((l = this.mountNode.querySelector(".login-button"))) {
      l.onclick = () => {
        console.log("///", global_data.loginPopup);
        global_data.loginPopup ? global_data.loginPopup.show() : null;
        this.data.close();
      };
    }
    if ((s = this.mountNode.querySelector(".signup-button"))) {
      s.onclick = () => {
        global_data.signupPopup ? global_data.signupPopup.show() : null;
        this.data.close();
      };
    }
  };
}
