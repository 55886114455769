import { Component } from "./GenericComponent";
import { FieldsEditListComponent } from "./FieldsEditList";
import {
  TextFieldEditComponent,
  PasswordFieldEditComponent,
} from "./formFields";
import { getl, show, hide } from "../main_module";
import axios from "axios";
import { API_URL } from "../main_module";

export class SignupPopupComponent extends Component {
  getHTML = () => {
    return `
          <div class="login-popup-root">
              <h3>
                  ${getl("signup")}
              </h3>
              <div>
                  <div class="form-fields">
                  </div>
                  <div class="form-errors">
  
                  </div>
                  <div>   
                      <button class="submit-button">${getl("submit")}</button>
                  </div>
              </div>
          </div>
          `;
  };
  afterRender = () => {
    this.mountNode.querySelector(".submit-button").onclick = () => {
      hide(this.mountNode.querySelector(".form-errors"));
      const [vals] = this.renderedChildren[0].getValues();
      const { username, password, repeat_password, email } = vals;
      console.log("vals <>", vals);
      let err;
      if (!username) {
        err = getl("please_enter_login");
      }
      if (!email) {
        err = getl("please_enter_email");
      }
      if (!password) {
        err = getl("please_enter_password");
      }
      if (password?.length < 10) {
        err = getl("not_enough_strong_password");
      }
      if (!repeat_password) {
        err = getl("please_repeat_password");
      }
      if (repeat_password !== password) {
        err = getl("password_repeat_dont_match");
      }
      if (err) {
        this.mountNode.querySelector(".form-errors").innerHTML = err;
        show(this.mountNode.querySelector(".form-errors"));
      } else {
        console.log("--------------- REGISTER2");
        axios
          .post(API_URL + "/auth/register", {
            username,
            password,
            email,
          })
          .then(() => {
            this.data.close();
            showNotification(get("you_successfully_registered"), "success");
          })
          .catch((e) => {
            show(this.mountNode.querySelector(".form-errors"));
            this.mountNode.querySelector(".form-errors").innerHTML =
              e.text || getl("could_not_register");
          });
      }
    };
  };
  getChildren = () => {
    return [
      {
        selector: ".form-fields",
        component: FieldsEditListComponent,
        getData: () => {
          return {
            fieldsConfig: [
              {
                value: "",
                title: getl("username"),
                key: "username",
                component: TextFieldEditComponent,
              },
              {
                value: "",
                title: getl("email"),
                key: "email",
                component: TextFieldEditComponent,
              },
              {
                value: "",
                title: getl("password"),
                key: "password",
                component: PasswordFieldEditComponent,
              },
              {
                value: "",
                title: getl("repeat_password"),
                key: "repeat_password",
                component: PasswordFieldEditComponent,
              },
            ],
          };
        },
      },
    ];
  };
}
