import { qs, qsa, show, hide, getl, getFieldsListConfig } from "../main_module";
import { ImageUploadComponent } from "./ImageUpload";
import { FieldsEditListComponent } from "./FieldsEditList";
import { submitNewObject } from "../api";

let clicked = false;

const hideAddNewObjectPopup = () => {
  qs(".add-new-object-popup").style.display = "none";
};

export const attachAddNewObjectHandlers = (showPleaseLoginPopup) => {
  qs(".add-object-button").onclick = () => {
    if (global_data.isLoggedIn()) {
      switchToSelectPointState();
    } else {
      showPleaseLoginPopup();
    }
  };

  qs(".add-new-object-popup-overlay").onclick = () => {
    if (confirm(getl("do-you-want-to-close"))) {
      hideAddNewObjectPopup();
      backFromSelectPointState();
    }
  };
  /*qs('.please-select-point').onclick = () => {
              displayAddNewObjectPopup();
              backFromSelectPointState();
          }*/
};

const switchToSelectPointState = () => {
  qs(".please-select-point").style.display = "block";
  qs(".add-object-button-wrapper").style.display = "none";
  show(qs(".click-on-map-overlay"));
  qs("#map").classList.add("select-point");
  global_data.map.on("click", displayAMarkerForNewObject);
};
const backFromSelectPointState = () => {
  qs(".please-select-point").style.display = "none";
  qs(".add-object-button-wrapper").style.display = "block";
  qs("#map").classList.remove("select-point");
  global_data.map.off("click", displayAMarkerForNewObject);
  hide(qs(".click-on-map-overlay"));
};

const displayAMarkerForNewObject = (val) => {
  hide(qs(".click-on-map-overlay"));
  if (clicked) return;
  clicked = true;
  const latlng = L.latLng(val.latlng.lat, val.latlng.lng);
  const marker = L.marker(latlng).addTo(global_data.map);
  marker.addTo(global_data.map);
  setTimeout(() => {
    clicked = false;
    displayAddNewObjectPopup(val.latlng.lat, val.latlng.lng);
    setTimeout(() => {
      marker.removeFrom(global_data.map);
    }, 100);
  }, 500);
};

const displayAddNewObjectPopup = (lat, lng) => {
  qs(".add-new-object-popup").style.display = "block";
  qs(".add-new-object-popup-content").innerHTML = `
      
      <div class="new-object-form">
          <h2>
              ${getl("add-new-object-form-title")}
          </h2>
          <div class="form-field">
              <div class="form-caption">
                  Назва
              </div>
              <div class="form-value">
                  <input type="text" name="title" form-val>
              </div>
          </div>
          <div class="form-field">
              <div class="form-caption">
                  Опис
              </div>
              <div class="form-value">
                  <textarea name="description" form-val>
  
                  </textarea>
              </div>
          </div>
          <div class="form-field">
              <div class="form-caption">
                  Координати
              </div>
              <div class="form-value">
                  <div>Широта: <input type="text" name="latitude" value="${lat}" form-val /></div>
                  <div>Довгота: <input type="text" name="longitude" value="${lng}" form-val /></div>
              </div>
          </div>
          <div class="form-virtual-fields-list">
          </div>
          <div class="form-field">
              <div class="select-image-wrapper"></div>
          </div>
          <div class="form-errors">
  
          </div>
          <div class="form-field center">
              <button class="cancel-new-object-button">
                  ${getl("cancel")}
              </button>
              <button class="submit-new-object-button">
                  ${getl("add_new_object")}
              </button>
          </div>
      </div>
      
      `;

  const uploadImage = new ImageUploadComponent(qs(".select-image-wrapper"));
  uploadImage.render();

  const fieldsEdit = new FieldsEditListComponent(
    qs(".form-virtual-fields-list"),
    {
      fieldsConfig: getFieldsListConfig({}, "edit"),
    }
  );
  fieldsEdit.render();

  qs(".cancel-new-object-button").onclick = () => {
    if (confirm(getl("do-you-want-to-close"))) {
      hideAddNewObjectPopup();
      backFromSelectPointState();
    }
  };

  qs(".submit-new-object-button").onclick = () => {
    const vals = {};
    hide(qs(".form-errors"));
    qsa(".new-object-form [form-val]").forEach((node) => {
      const key = node.getAttribute("name");
      const val = node.value;
      vals[key] = val;
    });
    let err;
    if (!vals.title) {
      err = getl("please-enter-title");
    }
    if (!vals.latitude) {
      err = getl("please-enter-lat");
    }
    if (!vals.longitude) {
      err = getl("please-enter-lng");
    }
    if (err) {
      qs(".form-errors").innerHTML = err;
      show(qs(".form-errors"));
      return;
    }
    const [virtualFieldsValues, items] = fieldsEdit.getValues();
    vals.customFields = JSON.stringify(virtualFieldsValues);
    console.log("items", items);
    vals.items = items;

    const files = uploadImage.getFiles();
    submitNewObject(vals, files)
      .then((res) => {
        global_data.showNotification(
          getl("object_successfully_added"),
          "success"
        );
        hideAddNewObjectPopup();
        backFromSelectPointState();
        loadAndDisplayMarkers();
      })
      .catch((err) => {
        global_data.showNotification(err, "error");
        qs(".form-errors").innerHTML = err;
        show(qs(".form-errors"));
      });
  };
};
