import { qsa, qs } from "./main_module";
import { loadAndDisplayMarkers } from "./initMap";

const configgg = require("./config.json");

const addTranslations = async () => {
  global_data.texts = configgg.texts;
  qsa("[data-tlname]").forEach((node) => {
    const key = node.getAttribute("data-tlname");
    const tl = global_data.texts[key];
    node.innerHTML = tl;
  });
};

const resetMarkersAndClustering = () => {
  global_data.clusterLayer.removeLayers(Object.values(global_data.markersPool));
  window.global_data.markersPool = {};
};

export function initGlobalData() {
  window.global_data = {
    showNotification: (msg, type) => {
      var n = document.createElement("div");
      n.innerHTML = msg;
      n.classList.add(type);
      qs("#notifications").appendChild(n);
      setTimeout(() => {
        qs("#notifications").removeChild(n);
      }, 1000);
    },
    isLoggedIn: () => {
      console.log("token", token);
      return !!token;
    },
    markersPool: {},
    siteSpecificConfigs: require("./archimapa/config.json"),
    setFilters: (new_filters) => {
      // refresh markers
      resetMarkersAndClustering();
      window.global_data.filters = new_filters;
      loadAndDisplayMarkers();
    },
  };
  addTranslations();
}
