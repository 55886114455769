import L from "leaflet";
import { loadObjects } from "./api";
import { qs, runSiteSpecific } from "./main_module";
import { ObjectPopupComponent } from "./components/ObjectPopup";

let popup_ids = 0;

const getObjectPopup = (popup_id) => {
  return `
        <div class="object-popup-content-${popup_id}" style="width: 400px"></div>
    `;
};

const objectToMarker = (obj) => {
  const latlng = L.latLng(obj.latitude, obj.longitude);
  const marker = L.marker(latlng, {
    icon: runSiteSpecific("getMarkerIcon", obj),
  });
  return marker;
};

export const loadAndDisplayMarkers = async (val) => {
  const bounds = window.global_data.map.getBounds();
  const params = {
    north: bounds.getNorth(),
    south: bounds.getSouth(),
    east: bounds.getEast(),
    west: bounds.getWest(),
  };
  const newObjects = await loadObjects(params);

  newObjects.forEach((obj) => {
    if (!global_data.markersPool[obj.id]) {
      const isMainMarker = window.global_data?.selectedMarkerObjectId == obj.id;
      const marker = objectToMarker(obj);
      if (isMainMarker) {
        marker.addTo(window.global_data.map);
      } else {
        marker.addTo(window.global_data.clusterLayer);
      }
      marker.on("click", () => {
        const popup_id = ++popup_ids;
        marker.unbindPopup().bindPopup(getObjectPopup(popup_id)).openPopup();
        const popup = new ObjectPopupComponent(
          qs(".object-popup-content-" + popup_id),
          obj
        );
        popup.render();
      });

      window.global_data.markersPool[obj.id] = marker;
    }
  });
};

export const initMap = (initialCoords, id = "map") => {
  const map = L.map(id).setView(
    initialCoords && initialCoords[0]
      ? [initialCoords[0], initialCoords[1]]
      : [49.43665468061089, 32.05870628356934],
    13
  );
  const clusterLayer = L.markerClusterGroup();
  window.global_data.markersPool = {};
  window.global_data.map = map;
  window.global_data.clusterLayer = clusterLayer;
  L.tileLayer.provider("OpenStreetMap.Mapnik").addTo(map);
  loadAndDisplayMarkers();
  map.on("moveend", loadAndDisplayMarkers);
  map.addLayer(clusterLayer);
  return {
    map,
  };
};
