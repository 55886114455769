import { Component } from "./GenericComponent";
import {fetchItemsByCategory} from '../api';
 
export class TextFieldDisplayComponent extends Component {
    name = "TextFieldDisplayComponent";
    getHTML = () => {
      return `
              <div>
                  ${this.data.title}:
                  ${this.data.value}
              </div>
          `;
    };
  }
  
  export class SelectFieldDisplayComponent extends Component {
    name = "SelectFieldDisplayComponent";
    getHTML = () => {
      return `
              <div>
                  ${this.data.title}:
                  ${(this.data?.value instanceof Array) ? this.data?.value?.map(val => val.name).join(', ') : this.data.value}
              </div>
          `;
    };
  }
  
  export class TextFieldEditComponent extends Component {
    name = "TextFieldEditComponent";
    getValue = () => {
      return this.mountNode.querySelector("input").value;
    };
    getHTML = () => {
      return `
              <div>
                  <div>
                      ${this.data.title}
                  </div>
                  <div>
                      <input type="text" value="${this.data.value}" />
                  </div>
              </div>
          `;
    };
  }
  
  export class PasswordFieldEditComponent extends TextFieldEditComponent {
    name = "PasswordFieldEditComponent";
    getHTML = () => {
      return `
              <div>
                  <div>
                      ${this.data.title}
                  </div>
                  <div>
                      <input type="password" value="${this.data.value}" />
                  </div>
              </div>
          `;
    };
  }
  
  export class CheckboxFieldEditComponent extends Component {
    name = "CheckboxFieldEditComponent";
    getHTML = () => {
      return `
              <div>
                  <div>
                      ${this.data.title}
                  </div>
                  <div>
                      <input type="checkbox" value="${this.data.value}" ${
        this.data.value ? "checked" : ""
      } />
                  </div>
              </div>
          `;
    };
    getValue = () => {
      return this.mountNode.querySelector("input").checked;
    };
  }
  
  export class SelectFieldEditComponent extends Component {
    name = "SelectFieldEditComponent";
    onCreate(){
        if(this.data.category_id){
            fetchItemsByCategory(this.data.category_id).then(res => {
                this.data.options = res.data.map(v => ({
                    value: v.id,
                    label: v.title,
                }));
                this.render();
            })
        }
    }
    getValue = () => {
      return this.mountNode.querySelector("select").value;
    };
    getHTML = () => {
      const ids = new Set(this.data.value instanceof Array ? this.data.value.map(v => v.id) : this.data.value);
      return `
              <div>
                  <div>
                      ${this.data.title}
                  </div>
                  <div>
                      <select>
                          ${this.data.options?
                            .map(
                              (o) =>
                                `<option value="${o.value}" ${
                                  ids.has(o.value) ? "selected" : ""
                                }>${o.label}</option>`
                            )
                            .join("")}
                      </select>
                  </div>
              </div>
          `;
    };
  }
  
  export const allFieldsConfig = {
    text: {
      display: TextFieldDisplayComponent,
      edit: TextFieldEditComponent,
    },
    select: {
      display: SelectFieldDisplayComponent,
      edit: SelectFieldEditComponent,
    },
  };