import { Component } from "./GenericComponent";
import { getl, show, hide } from "../main_module";

export class GenericPopupComponent extends Component {
  getChildren = () => {
    return [
      {
        selector: ".some-popup-content",
        component: this.data.contentComponent,
        getData: () => {
          return { ...this.data.data, close: this.hide.bind(this) };
        },
      },
    ];
  };
  getHTML = () => {
    return `
          <div class="some-popup">
  
          <div class="some-popup-inside">
              <div class="some-popup-overlay">
              </div>
              <div class="some-popup-body">
                  <div class="close-popup">
                      ${getl("close")}
                  </div>
                  <div class="some-popup-content">
                      
                  </div>
              </div>
          </div>
      </div>
          `;
  };
  show = () => {
    show(this.mountNode.children[0]);
  };
  hide = () => {
    hide(this.mountNode.children[0]);
  };
  afterRender = () => {
    const confirmAndClose = () => {
      if (confirm(getl("do-you-want-to-close"))) {
        this.renderedChildren[0].onClose
          ? this.renderedChildren[0].onClose()
          : null;
        this.hide();
      }
    };
    this.mountNode.querySelector(".some-popup-overlay").onclick =
      confirmAndClose;
    this.mountNode.querySelector(".close-popup").onclick = confirmAndClose;
  };
}
